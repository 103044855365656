// Vue pager
import Vue from 'vue';
import Pager from './overview/pager.vue';

(() => {
    window.vue = new Vue({
        el: '.pagerRow',
        components: {
            Pager,
        }
    });
})();