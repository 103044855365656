<script>
    export default {
        name: 'Pager',
        props: {
            _currentPage: {
                default: 1,
            },
            _totalPages: {
                default: 1,
            },
            _disabled: {
                default: false
            }
        },
        data: function() {
            return {
                currentPage: this._currentPage,
                totalPages: this._totalPages,
                disabled: this._disabled
            };
        },
        methods: {
            changePage: function (page) {
                if (page == 'first') {
                    this.currentPage = 1;
                } else if (page == 'last') {
                    this.currentPage = this.totalPages;
                } else {
                    this.currentPage = this.currentPage + page;
                }

                this.$emit('changePage', this.currentPage);
            }
        }
    }
</script>

<template>
    <div class="pager" v-if="!disabled && totalPages > 1">
        <div class="pages">
            <button class="previous" @click.prevent="changePage(-1)" :disabled="this.currentPage <= 1" aria-label="Blader naar de vorige pagina">
                <svg class="sprite icon_link_arrow">
                    <use xlink:href="#icon_link_arrow" />
                </svg>
                <span>vorige</span>
            </button>

            <div class="pageContainer">

                <a href="" @click.prevent="changePage('first')" class="page" v-if="this.currentPage > 2" aria-label="Blader naar de eerste pagina">
                    <span>1</span>
                </a>

                <span class="dots" v-if="this.currentPage > 4 && this.totalPages > 7">...</span>

                <a class="page" @click.prevent="changePage(-5)" v-if="this.currentPage - 5 > 0 && this.currentPage >= this.totalPages - 0 && this.currentPage > 6 && this.totalPages < 8" tabindex="0" :aria-label="'Blader naar pagina ' + (Number(this.currentPage) - Number(5))">{{ this.currentPage - 5 }}</a>

                <a class="page" @click.prevent="changePage(-4)" v-if="this.currentPage - 4 > 0 && this.currentPage >= this.totalPages - 0 && this.currentPage > 5" tabindex="0" :aria-label="'Blader naar pagina ' + (Number(this.currentPage) - Number(4))">{{ this.currentPage - 4 }}</a>
                <a class="page" @click.prevent="changePage(-4)" v-if="this.currentPage == 6 && this.totalPages < 8 && this.totalPages != 6" tabindex="0" :aria-label="'Blader naar pagina ' + (Number(this.currentPage) - Number(4))">{{ this.currentPage - 4 }}</a>

                <a class="page" @click.prevent="changePage(-3)" v-if="this.currentPage - 3 > 0 && this.currentPage >= this.totalPages - 1 && this.currentPage > 4" tabindex="0" :aria-label="'Blader naar pagina ' + (Number(this.currentPage) - Number(3))">{{ this.currentPage - 3 }}</a>
                <a class="page" @click.prevent="changePage(-3)" v-if="this.currentPage == 5 && this.totalPages < 8 && this.totalPages > 6" tabindex="0" :aria-label="'Blader naar pagina ' + (Number(this.currentPage) - Number(3))">{{ this.currentPage - 3 }}</a>

                <a class="page" @click.prevent="changePage(-2)" v-if="this.currentPage - 2 > 0 && this.currentPage >= this.totalPages - 2 && this.currentPage > 4" tabindex="0" :aria-label="'Blader naar pagina ' + (Number(this.currentPage) - Number(2))">{{ this.currentPage - 2 }}</a>
                <a class="page" @click.prevent="changePage(-2)" v-if="this.currentPage == 4" tabindex="0" :aria-label="'Blader naar pagina ' + (Number(this.currentPage) - Number(2))">{{ this.currentPage - 2 }}</a>

                <a class="page" @click.prevent="changePage(-1)" v-if="this.currentPage - 1 > 0" tabindex="0" :aria-label="'Blader naar pagina ' + (Number(this.currentPage) - Number(1))">{{ this.currentPage - 1 }}</a>

                <a class="page active" @click.prevent role="none" tabindex="-1">
                    <span>{{ this.currentPage }}</span>
                </a>

                <a class="page" @click.prevent="changePage(1)" v-if="this.currentPage + 1 <= this.totalPages" tabindex="0" :aria-label="'Blader naar pagina ' + (Number(this.currentPage) + Number(1))">{{ this.currentPage + 1 }}</a>

                <a class="page" @click.prevent="changePage(2)" v-if="this.currentPage == 4 && this.totalPages == 6" tabindex="0" :aria-label="'Blader naar pagina ' + (Number(this.currentPage) + Number(2))">{{ this.currentPage + 2 }}</a>
                <a class="page" @click.prevent="changePage(2)" v-if="this.currentPage + 3 == this.totalPages && this.totalPages > 6" tabindex="0" :aria-label="'Blader naar pagina ' + (Number(this.currentPage) + Number(2))">{{ this.currentPage + 2 }}</a>
                <a class="page" @click.prevent="changePage(2)" v-if="this.currentPage + 2 <= this.totalPages && this.currentPage <= 3" tabindex="0" :aria-label="'Blader naar pagina ' + (Number(this.currentPage) + Number(2))">{{ this.currentPage + 2 }}</a>

                <a class="page" @click.prevent="changePage(3)" v-if="this.currentPage + 3 <= this.totalPages && this.totalPages > 7 && this.currentPage <= 2" tabindex="0" :aria-label="'Blader naar pagina ' + (Number(this.currentPage) + Number(3))">{{ this.currentPage + 3 }}</a>
                <a class="page" @click.prevent="changePage(3)" v-if="this.currentPage + 3 <= this.totalPages && this.currentPage <= 3 && this.totalPages < 8" tabindex="0" :aria-label="'Blader naar pagina ' + (Number(this.currentPage) + Number(3))">{{ this.currentPage + 3 }}</a>

                <a class="page" @click.prevent="changePage(4)" v-if="this.currentPage + 4 <= this.totalPages && this.totalPages > 7 && this.currentPage == 1" tabindex="0" :aria-label="'Blader naar pagina ' + (Number(this.currentPage) + Number(4))">{{ this.currentPage + 4 }}</a>
                <a class="page" @click.prevent="changePage(4)" v-if="this.currentPage + 4 <= this.totalPages && this.currentPage <= 2 && this.totalPages < 8" tabindex="0" :aria-label="'Blader naar pagina ' + (Number(this.currentPage) + Number(4))">{{ this.currentPage + 4 }}</a>

                <a class="page" @click.prevent="changePage(5)" v-if="this.currentPage + 5 <= this.totalPages && this.currentPage == 1 && this.totalPages < 8" tabindex="0" :aria-label="'Blader naar pagina ' + (Number(this.currentPage) + Number(5))">{{ this.currentPage + 5 }}</a>

                <span class="dots" v-if="this.currentPage + 3 < this.totalPages && this.totalPages > 7">...</span>

                <a href="" @click.prevent="changePage('last')" class="page" :disabled="this.currentPage >= this.totalPages" v-if="this.currentPage + 1 < this.totalPages && this.totalPages > 6" aria-label="Blader naar de laatste pagina">
                    <span>{{ this.totalPages }}</span>
                </a>

            </div>

            <button class="next" @click.prevent="changePage(1)" :disabled="this.currentPage >= this.totalPages" aria-label="Blader naar de volgende pagina">
                <span>volgende</span>
                <svg class="sprite icon_link_arrow">
                    <use xlink:href="#icon_link_arrow" />
                </svg>
            </button>
        </div>
    </div>
</template>