var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.disabled && _vm.totalPages > 1
    ? _c("div", { staticClass: "pager" }, [
        _c("div", { staticClass: "pages" }, [
          _c(
            "button",
            {
              staticClass: "previous",
              attrs: {
                disabled: this.currentPage <= 1,
                "aria-label": "Blader naar de vorige pagina",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.changePage(-1)
                },
              },
            },
            [
              _c("svg", { staticClass: "sprite icon_link_arrow" }, [
                _c("use", { attrs: { "xlink:href": "#icon_link_arrow" } }),
              ]),
              _vm._v(" "),
              _c("span", [_vm._v("vorige")]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "pageContainer" }, [
            this.currentPage > 2
              ? _c(
                  "a",
                  {
                    staticClass: "page",
                    attrs: {
                      href: "",
                      "aria-label": "Blader naar de eerste pagina",
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changePage("first")
                      },
                    },
                  },
                  [_c("span", [_vm._v("1")])]
                )
              : _vm._e(),
            _vm._v(" "),
            this.currentPage > 4 && this.totalPages > 7
              ? _c("span", { staticClass: "dots" }, [_vm._v("...")])
              : _vm._e(),
            _vm._v(" "),
            this.currentPage - 5 > 0 &&
            this.currentPage >= this.totalPages - 0 &&
            this.currentPage > 6 &&
            this.totalPages < 8
              ? _c(
                  "a",
                  {
                    staticClass: "page",
                    attrs: {
                      tabindex: "0",
                      "aria-label":
                        "Blader naar pagina " +
                        (Number(this.currentPage) - Number(5)),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changePage(-5)
                      },
                    },
                  },
                  [_vm._v(_vm._s(this.currentPage - 5))]
                )
              : _vm._e(),
            _vm._v(" "),
            this.currentPage - 4 > 0 &&
            this.currentPage >= this.totalPages - 0 &&
            this.currentPage > 5
              ? _c(
                  "a",
                  {
                    staticClass: "page",
                    attrs: {
                      tabindex: "0",
                      "aria-label":
                        "Blader naar pagina " +
                        (Number(this.currentPage) - Number(4)),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changePage(-4)
                      },
                    },
                  },
                  [_vm._v(_vm._s(this.currentPage - 4))]
                )
              : _vm._e(),
            _vm._v(" "),
            this.currentPage == 6 && this.totalPages < 8 && this.totalPages != 6
              ? _c(
                  "a",
                  {
                    staticClass: "page",
                    attrs: {
                      tabindex: "0",
                      "aria-label":
                        "Blader naar pagina " +
                        (Number(this.currentPage) - Number(4)),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changePage(-4)
                      },
                    },
                  },
                  [_vm._v(_vm._s(this.currentPage - 4))]
                )
              : _vm._e(),
            _vm._v(" "),
            this.currentPage - 3 > 0 &&
            this.currentPage >= this.totalPages - 1 &&
            this.currentPage > 4
              ? _c(
                  "a",
                  {
                    staticClass: "page",
                    attrs: {
                      tabindex: "0",
                      "aria-label":
                        "Blader naar pagina " +
                        (Number(this.currentPage) - Number(3)),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changePage(-3)
                      },
                    },
                  },
                  [_vm._v(_vm._s(this.currentPage - 3))]
                )
              : _vm._e(),
            _vm._v(" "),
            this.currentPage == 5 && this.totalPages < 8 && this.totalPages > 6
              ? _c(
                  "a",
                  {
                    staticClass: "page",
                    attrs: {
                      tabindex: "0",
                      "aria-label":
                        "Blader naar pagina " +
                        (Number(this.currentPage) - Number(3)),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changePage(-3)
                      },
                    },
                  },
                  [_vm._v(_vm._s(this.currentPage - 3))]
                )
              : _vm._e(),
            _vm._v(" "),
            this.currentPage - 2 > 0 &&
            this.currentPage >= this.totalPages - 2 &&
            this.currentPage > 4
              ? _c(
                  "a",
                  {
                    staticClass: "page",
                    attrs: {
                      tabindex: "0",
                      "aria-label":
                        "Blader naar pagina " +
                        (Number(this.currentPage) - Number(2)),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changePage(-2)
                      },
                    },
                  },
                  [_vm._v(_vm._s(this.currentPage - 2))]
                )
              : _vm._e(),
            _vm._v(" "),
            this.currentPage == 4
              ? _c(
                  "a",
                  {
                    staticClass: "page",
                    attrs: {
                      tabindex: "0",
                      "aria-label":
                        "Blader naar pagina " +
                        (Number(this.currentPage) - Number(2)),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changePage(-2)
                      },
                    },
                  },
                  [_vm._v(_vm._s(this.currentPage - 2))]
                )
              : _vm._e(),
            _vm._v(" "),
            this.currentPage - 1 > 0
              ? _c(
                  "a",
                  {
                    staticClass: "page",
                    attrs: {
                      tabindex: "0",
                      "aria-label":
                        "Blader naar pagina " +
                        (Number(this.currentPage) - Number(1)),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changePage(-1)
                      },
                    },
                  },
                  [_vm._v(_vm._s(this.currentPage - 1))]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "page active",
                attrs: { role: "none", tabindex: "-1" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [_c("span", [_vm._v(_vm._s(this.currentPage))])]
            ),
            _vm._v(" "),
            this.currentPage + 1 <= this.totalPages
              ? _c(
                  "a",
                  {
                    staticClass: "page",
                    attrs: {
                      tabindex: "0",
                      "aria-label":
                        "Blader naar pagina " +
                        (Number(this.currentPage) + Number(1)),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changePage(1)
                      },
                    },
                  },
                  [_vm._v(_vm._s(this.currentPage + 1))]
                )
              : _vm._e(),
            _vm._v(" "),
            this.currentPage == 4 && this.totalPages == 6
              ? _c(
                  "a",
                  {
                    staticClass: "page",
                    attrs: {
                      tabindex: "0",
                      "aria-label":
                        "Blader naar pagina " +
                        (Number(this.currentPage) + Number(2)),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changePage(2)
                      },
                    },
                  },
                  [_vm._v(_vm._s(this.currentPage + 2))]
                )
              : _vm._e(),
            _vm._v(" "),
            this.currentPage + 3 == this.totalPages && this.totalPages > 6
              ? _c(
                  "a",
                  {
                    staticClass: "page",
                    attrs: {
                      tabindex: "0",
                      "aria-label":
                        "Blader naar pagina " +
                        (Number(this.currentPage) + Number(2)),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changePage(2)
                      },
                    },
                  },
                  [_vm._v(_vm._s(this.currentPage + 2))]
                )
              : _vm._e(),
            _vm._v(" "),
            this.currentPage + 2 <= this.totalPages && this.currentPage <= 3
              ? _c(
                  "a",
                  {
                    staticClass: "page",
                    attrs: {
                      tabindex: "0",
                      "aria-label":
                        "Blader naar pagina " +
                        (Number(this.currentPage) + Number(2)),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changePage(2)
                      },
                    },
                  },
                  [_vm._v(_vm._s(this.currentPage + 2))]
                )
              : _vm._e(),
            _vm._v(" "),
            this.currentPage + 3 <= this.totalPages &&
            this.totalPages > 7 &&
            this.currentPage <= 2
              ? _c(
                  "a",
                  {
                    staticClass: "page",
                    attrs: {
                      tabindex: "0",
                      "aria-label":
                        "Blader naar pagina " +
                        (Number(this.currentPage) + Number(3)),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changePage(3)
                      },
                    },
                  },
                  [_vm._v(_vm._s(this.currentPage + 3))]
                )
              : _vm._e(),
            _vm._v(" "),
            this.currentPage + 3 <= this.totalPages &&
            this.currentPage <= 3 &&
            this.totalPages < 8
              ? _c(
                  "a",
                  {
                    staticClass: "page",
                    attrs: {
                      tabindex: "0",
                      "aria-label":
                        "Blader naar pagina " +
                        (Number(this.currentPage) + Number(3)),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changePage(3)
                      },
                    },
                  },
                  [_vm._v(_vm._s(this.currentPage + 3))]
                )
              : _vm._e(),
            _vm._v(" "),
            this.currentPage + 4 <= this.totalPages &&
            this.totalPages > 7 &&
            this.currentPage == 1
              ? _c(
                  "a",
                  {
                    staticClass: "page",
                    attrs: {
                      tabindex: "0",
                      "aria-label":
                        "Blader naar pagina " +
                        (Number(this.currentPage) + Number(4)),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changePage(4)
                      },
                    },
                  },
                  [_vm._v(_vm._s(this.currentPage + 4))]
                )
              : _vm._e(),
            _vm._v(" "),
            this.currentPage + 4 <= this.totalPages &&
            this.currentPage <= 2 &&
            this.totalPages < 8
              ? _c(
                  "a",
                  {
                    staticClass: "page",
                    attrs: {
                      tabindex: "0",
                      "aria-label":
                        "Blader naar pagina " +
                        (Number(this.currentPage) + Number(4)),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changePage(4)
                      },
                    },
                  },
                  [_vm._v(_vm._s(this.currentPage + 4))]
                )
              : _vm._e(),
            _vm._v(" "),
            this.currentPage + 5 <= this.totalPages &&
            this.currentPage == 1 &&
            this.totalPages < 8
              ? _c(
                  "a",
                  {
                    staticClass: "page",
                    attrs: {
                      tabindex: "0",
                      "aria-label":
                        "Blader naar pagina " +
                        (Number(this.currentPage) + Number(5)),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changePage(5)
                      },
                    },
                  },
                  [_vm._v(_vm._s(this.currentPage + 5))]
                )
              : _vm._e(),
            _vm._v(" "),
            this.currentPage + 3 < this.totalPages && this.totalPages > 7
              ? _c("span", { staticClass: "dots" }, [_vm._v("...")])
              : _vm._e(),
            _vm._v(" "),
            this.currentPage + 1 < this.totalPages && this.totalPages > 6
              ? _c(
                  "a",
                  {
                    staticClass: "page",
                    attrs: {
                      href: "",
                      disabled: this.currentPage >= this.totalPages,
                      "aria-label": "Blader naar de laatste pagina",
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changePage("last")
                      },
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(this.totalPages))])]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "next",
              attrs: {
                disabled: this.currentPage >= this.totalPages,
                "aria-label": "Blader naar de volgende pagina",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.changePage(1)
                },
              },
            },
            [
              _c("span", [_vm._v("volgende")]),
              _vm._v(" "),
              _c("svg", { staticClass: "sprite icon_link_arrow" }, [
                _c("use", { attrs: { "xlink:href": "#icon_link_arrow" } }),
              ]),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }